export default {
  dark: "#282c35",
  secondaryDark: "#363c48",
  codingDark: " #011627",
  green: "#a2e5b5",
  pink: "#ffa7c4",
  orange: "#ffc4a7",
  gray: "rgba(255, 255, 255, 0.88)",
  blue: "#94dae2",

  darker: "#484B6B",

  lightgreen: "#90ec90",
  mediumgreen: "#59d28f",
  darkergreen: "#51c082",

  purple: "#6a72d0",
}
