import React from "react"
import { Link } from "gatsby"
import colors from "./../../colors"

import { rhythm, scale } from "../utils/typography"
import styled from "styled-components"

const LayoutStyle = styled.div`
  background-color: ${colors.dark};
  height: 100%;
  text-rendering: optimizeLegibility;

  #site-title {
    color: white;
  }

  #site-title-small {
    color: ${colors.green};
  }

  .container-max {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 51rem;
    padding: 2.6rem 1.3125rem;
  }
  h1 {
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
  }

  a {
    color: ${colors.green};
  }

  p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    color: ${colors.gray};
    font-size: 1.05rem;
    opacity: 0.88;
  }

  footer {
    margin-top: 4.73rem;
    color: ${colors.gray};
  }
`

const Layout = ({ location, title, children, social }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
        id="site-title"
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
        id="site-title-small"
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <LayoutStyle>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <div className="container-max">
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          <a href={`https://twitter.com/${social?.twitter}`}> Twitter </a> •
          <a href={`https://github.com/${social?.github}`}> Github </a> •
          <a href={`https://instagram.com/${social?.instagram}`}> Instagram </a>
        </footer>
      </div>
    </LayoutStyle>
  )
}

export default Layout
