import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"
import colors from "../../colors"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    h3: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    p: {
      bodyColor: colors.gray,
    },
    blockquote: {
      "border-left-color": colors.blue,
    },
    img: {
      "text-decoration": "none",
    },
    a: {
      "text-decoration": "none",
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)
typography.options.bodyColor = colors.gray

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
